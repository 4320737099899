import { useQuery } from '@apollo/react-hooks';
import Auth from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import React, { FC } from 'react';
import AUTH_CHECK_QUERY from './AuthCheckQuery';
import { AuthContext, AuthContextState } from './AuthContext';
import { AuthCheckQuery } from './types/AuthCheckQuery';

// @ts-ignore
window.Auth = Auth;

export interface AuthUser extends CognitoUser {
  type: 'Client' | 'Creator';
  avatarPublicId: string;
}

type AuthProps = {
  children: React.ReactNode | ((authState: AuthContextState) => React.ReactNode);
};

const Authenticator: FC = ({ children }) => {
  const { loading, data, error, refetch } = useQuery<AuthCheckQuery>(AUTH_CHECK_QUERY, {});

  const isAuthenticated = (!error && !!data?.self?.adminLevel) ?? false;

  if (loading) {
    return <>loading</>;
  }

  if (!isAuthenticated) {
    return <>Not authenticated</>;
  }

  if (error) {
    // redirect?
    window.location.assign('/app/sign-in');
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        user: data?.self,
        signIn: (email: string, password: string) => {
          return Auth.signIn(email.toLowerCase(), password).then(refetch);
        },
        signOut: () => {
          return Auth.signOut().then(refetch);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default Authenticator;
