import { CognitoUser } from 'amazon-cognito-identity-js';
import * as React from 'react';

export type AuthContextState = {
  loading: boolean;
  isAuthenticated: boolean;
  user: null | any;
  signIn: (email: string, password: string) => Promise<CognitoUser | any>;
  signOut: () => Promise<any>;
  // signIn: any;
};

export const defaultState = {
  loading: true,
  isAuthenticated: false,
  user: null,
  signIn: () =>
    new Promise(function () {
      return null;
    }),
  signOut: () =>
    new Promise(function () {
      return null;
    }),
};

export const AuthContext = React.createContext<AuthContextState>(defaultState);
