import Auth from '@aws-amplify/auth';
import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import config from '../config';

import introspectionQueryResultData from './fragmentTypes.json';

async function getToken() {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}

// @ts-ignore
const fetcher = (...args) => {
  // @ts-ignore
  return window.fetch(...args);
};

const url = config.AWS_APPSYNC_GRAPHQL_APP_ADMIN_URL;
const region = config.COGNITO_REGION;

// @ts-ignore
const auth: import('aws-appsync-auth-link').AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  // @ts-ignore
  jwtToken: async () => {
    try {
      return await getToken();
    } catch (error) {
      console.error(error);
    }
  },
};

const cacheOptions = {
  dataIdFromObject: (object: any) => {
    if (object.__typename === 'MediaItem') {
      // @ts-ignore
      return object.publicId;
    } else {
      return defaultDataIdFromObject(object);
    }
  },
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  }),
};

const httpLink = createHttpLink({ uri: url, fetch: fetcher });

const link = ApolloLink.from([
  createAuthLink({
    url,
    region,
    auth,
  }),
  createSubscriptionHandshakeLink(url, httpLink),
]);

let client: ApolloClient<any> | null = null;

export default function initApollo() {
  if (!client) {
    client = new ApolloClient({
      link: link,
      cache: new InMemoryCache(cacheOptions),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-first',
        },
        query: {
          fetchPolicy: 'cache-first',
        },
        mutate: {
          fetchPolicy: 'cache-first',
        },
      },
    });
  }
  return client;
}
