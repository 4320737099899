import { ApolloProvider } from '@apollo/react-hooks';
import { BaseStyles } from '@thehhub/core-components';
import theme from '@thehhub/web-theme';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'stream-chat-react/dist/css/index.css';
import { ThemeProvider } from 'styled-components/macro';
import App from './App';
import initApollo from './graphql/initApollo';
import './StreamCSSOverrides.css';

ReactDOM.render(
  <ApolloProvider client={initApollo()}>
    <BrowserRouter>
      <ThemeProvider
        theme={{
          ...theme,
          maxContainerWidth: 1024,
          colors: {
            ...theme.colors,
            pageBackground: '#fafafa !important',
          },
        }}
      >
        <BaseStyles />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);
