import BankOutlined from '@ant-design/icons/BankOutlined';
import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import CameraOutlined from '@ant-design/icons/CameraOutlined';
import DollarOutlined from '@ant-design/icons/DollarOutlined';
import PlaySquareOutlined from '@ant-design/icons/PlaySquareOutlined';
import StarOutlined from '@ant-design/icons/StarOutlined';
import TagsOutlined from '@ant-design/icons/TagsOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import Auth from '@aws-amplify/auth';
import { buildCloudinaryImageUrl } from '@thehhub/cloudinary-utils';
import { Box, Container } from '@thehhub/core-components';
import { AppLayout } from '@thehhub/web-components';
import { Menu } from 'antd';
import React, { FC, useContext, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  hasGameOneAccess,
  hasGameThreeAccess,
  hasGameTwoAccess,
  hasSuperAdminAccess,
  hasTagAdminAccess,
  hasUserAdminAccess,
  isAuthorized,
} from '../../authorization';
import { AuthContext } from '../authenticator/AuthContext';

const { SubMenu, Item } = Menu;

const MenuItem = styled(Item)`
  //font-size: 13px;
  //text-transform: uppercase;
  ////font-weight: bold;
`;

const AdminLayout: FC<import('react-router').RouteComponentProps> = ({ children, history, location }) => {
  const { loading, user } = useContext(AuthContext);
  const menuRef = useRef<React.ReactElement | any>(null);

  useEffect(
    function determineInitialActivePage() {
      if (location.pathname !== '/admin' && location.pathname !== '/admin/') {
        return;
      }
      const Menu = menuRef.current;
      const [first] = (Menu?.props.children ?? []).filter((child: any) => {
        // return !!child.key;
        if (!!child.key) {
          return true;
        } else if (child.props && Array.isArray(child.props.children)) {
          return child.props.children.filter((chil: any) => {
            return !!chil.key;
          });
        }
        return false;
      });
      if (!first) {
        return;
      } else if (first.key) {
        // set first
        history.replace(first.key);
      } else if (Array.isArray(first.props.children)) {
        const [nested] = first.props.children.filter((chil: any) => {
          return !!chil.key;
        });
        history.replace(nested.key);
      }
    },
    [loading, location, history]
  );

  // if (loading) {
  //   return <>Loading</>;
  // }

  // if (!loading && !isAuthenticated) {
  //   return <>Not authenticated</>;
  // }

  const handleClick = (e) => {
    // console.log('click ', e);
    history.push(e.key);
  };

  let selectedKey = location.pathname;
  if (selectedKey.startsWith('/admin/creators')) {
    selectedKey = '/admin/creators/search';
  } else if (selectedKey.startsWith('/admin/clients')) {
    selectedKey = '/admin/clients/search';
  } else if (selectedKey.startsWith('/admin/jobs')) {
    selectedKey = '/admin/jobs/search';
  }

  return (
    <AppLayout
      urlPathname="/admin"
      userType="Creator"
      onSignOut={() => {
        Auth.signOut().then(() => {
          window.location.assign('/app/sign-in');
        });
      }}
      userAvatarSrc={buildCloudinaryImageUrl(user?.avatar?.publicId, 'w_120,h_120')}
      userName={user?.name || user?.creatorName}
      userTagline={user?.skill?.name}
      hasAdminAccess
    >
      <Box position="sticky" top={0} zIndex={2} bg="white" as="header" boxShadow="card" pt={1} textAlign="center">
        <Menu
          ref={menuRef}
          selectedKeys={[selectedKey]}
          mode="horizontal"
          style={{ textAlign: 'center' }}
          onClick={handleClick}
        >
          {hasUserAdminAccess(user?.adminLevel) && (
            <SubMenu
              title={
                <span className="submenu-title-wrapper">
                  <CameraOutlined /> Creators
                </span>
              }
            >
              <Menu.Item key="/admin/creators/search">Creator Search</Menu.Item>
              <Menu.Item key="/admin/creators/pending">Review Applications</Menu.Item>
            </SubMenu>
          )}

          {hasUserAdminAccess(user?.adminLevel) && (
              <SubMenu
                  title={
                    <span className="submenu-title-wrapper">
                  <UserOutlined /> Clients
                </span>
                  }
              >
                <Menu.Item key="/admin/clients/search">Client Search</Menu.Item>
                <Menu.Item key="/admin/clients/pending">Review Applications</Menu.Item>
              </SubMenu>
          )}

          {hasUserAdminAccess(user?.adminLevel) && (
            <MenuItem key="/admin/jobs/search">
              <BankOutlined />
              Jobs
            </MenuItem>
          )}

          {isAuthorized(user?.adminLevel) && (
            <SubMenu
              title={
                <span className="submenu-title-wrapper">
                  <PlaySquareOutlined />
                  Games
                </span>
              }
            >
              {hasGameOneAccess(user?.adminLevel) && <Menu.Item key="/admin/games/game-one">Game 1</Menu.Item>}
              {hasGameTwoAccess(user?.adminLevel) && <Menu.Item key="/admin/games/game-two">Game 2</Menu.Item>}
            </SubMenu>
          )}
          {hasGameThreeAccess(user?.adminLevel) && (
            <SubMenu
              key="/admin/bounties"
              title={
                <span className="submenu-title-wrapper">
                  <TagsOutlined />
                  Bounties
                </span>
              }
            >
              <Menu.Item key="/admin/bounties">All Bounties</Menu.Item>
              <Menu.Item key="/admin/bounties/create">Bounty Creator</Menu.Item>
            </SubMenu>
          )}
          {hasTagAdminAccess(user?.adminLevel) && (
            <SubMenu
              title={
                <span className="submenu-title-wrapper">
                  <DollarOutlined />
                  Discount Codes
                </span>
              }
            >
              <Menu.Item key="/admin/discounts/search">Search Discount Codes</Menu.Item>
              <Menu.Item key="/admin/discounts/create">Create New Discount</Menu.Item>
            </SubMenu>
          )}

          {hasTagAdminAccess(user?.adminLevel) && (
            <SubMenu
              title={
                <span className="submenu-title-wrapper">
                  <StarOutlined />
                  Featured Content
                </span>
              }
            >
              <MenuItem key="/admin/featured-work">Featured Work</MenuItem>
              <MenuItem key="/admin/featured-creators">Featured Creators</MenuItem>
            </SubMenu>
          )}

          {hasSuperAdminAccess(user?.adminLevel) && (
            <MenuItem key="/admin/reports">
              <BarChartOutlined /> Reports
            </MenuItem>
          )}
        </Menu>
      </Box>
      <Container maxWidth={1200} p={[3, 3, 3, 4]}>
        {children}
      </Container>
    </AppLayout>
  );
};

export default withRouter(AdminLayout);
