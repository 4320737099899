export const ADMIN_LEVELS_ENUM = {
  NONE: 'NONE',
  GAME_1: 'GAME_1',
  GAME_2: 'GAME_2',
  GAME_3: 'GAME_3',
  USER: 'USER',
  TAG: 'TAG',
  SUPER: 'SUPER',
};

export const ADMIN_LEVELS = [
  ADMIN_LEVELS_ENUM.NONE,
  ADMIN_LEVELS_ENUM.GAME_1,
  ADMIN_LEVELS_ENUM.GAME_2,
  ADMIN_LEVELS_ENUM.GAME_3,
  ADMIN_LEVELS_ENUM.USER,
  ADMIN_LEVELS_ENUM.TAG,
  ADMIN_LEVELS_ENUM.SUPER,
];

export const isAuthorized = (level: string) => {
  console.debug('isAuthorized(%s)', level);
  return ADMIN_LEVELS.indexOf(level) > 0;
};

export const hasGameOneAccess = (level: string) => {
  console.debug('hasGameOneAccess(%s)', level);
  return ADMIN_LEVELS.indexOf(level) > 0;
};

export const hasGameTwoAccess = (level: string) => {
  console.debug('hasGameTwoAccess(%s)', level);
  return ADMIN_LEVELS.indexOf(level) > 1;
};

export const hasGameThreeAccess = (level: string) => {
  console.debug('hasGameThreeAccess(%s)', level);
  return ADMIN_LEVELS.indexOf(level) > 2;
};

export const hasUserAdminAccess = (level: string) => {
  console.debug('hasUserAdminAccess(%s)', level);
  return ADMIN_LEVELS.indexOf(level) > 3;
};

export const hasTagAdminAccess = (level: string) => {
  console.debug('hasTagAdminAccess(%s)', level);
  return ADMIN_LEVELS.indexOf(level) > 4;
};

export const hasSuperAdminAccess = (level: string) => {
  console.debug('hasSuperAdminAccess(%s)', level);
  return ADMIN_LEVELS.indexOf(level) > 5;
};
