import gql from 'graphql-tag';

export default gql`
  query AuthCheckQuery {
    self {
      ... on Creator {
        creatorId: id
        adminLevel
        creatorName: name
        skill {
          name
        }
        avatar {
          publicId
        }
        user {
          givenName
          familyName
        }
        rating {
          tier
        }
      }
      ... on Client {
        clientId: id
        adminLevel
        name
        avatar {
          publicId
        }
      }
    }
  }
`;
