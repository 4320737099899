import configureAuth from '@thehhub/aws-auth-configure';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminLayout from './components/admin-layout';
import Authenticator from './components/authenticator';
// import StreamProvider from './components/conversation-modal/StreamProvider';

const StreamProvider = lazy(() =>
  import(/* webpackChunkName: "StreamProvider" */ './components/conversation-modal/StreamProvider')
);

configureAuth(process.env.REACT_APP_STAGE);

const routes = [
  {
    path: '/admin/creators',
    component: lazy(() => import(/* webpackChunkName: "CreatorManageRoutes" */ './pages/CreatorManageRoutes')),
    exact: false,
  },
  {
    path: '/admin/clients',
    component: lazy(() => import(/* webpackChunkName: "ClientManageRoutes" */ './pages/ClientManageRoutes')),
    exact: false,
  },
  {
    path: '/admin/jobs',
    component: lazy(() => import(/* webpackChunkName: "JobManageRoutes" */ './pages/JobManageRoutes')),
    exact: false,
  },
  {
    path: '/admin/discounts',
    component: lazy(() => import(/* webpackChunkName: "DiscountCodeRoutes" */ './pages/DiscountCodeRoutes')),
    exact: false,
  },
  {
    path: '/admin/games',
    component: lazy(() => import(/* webpackChunkName: "GamesRoutes" */ './pages/GamesRoutes')),
    exact: false,
  },

  {
    path: '/admin/bounties',
    component: lazy(() => import(/* webpackChunkName: "BountyRoutes" */ './pages/BountyRoutes')),
    exact: false,
  },

  {
    path: '/admin/featured-work',
    component: lazy(() => import(/* webpackChunkName: "FeaturedWorkRoutes" */ './pages/FeaturedWorkRoutes')),
    exact: false,
  },
  {
    path: '/admin/featured-creators',
    component: lazy(() => import(/* webpackChunkName: "FeaturedCreators" */ './pages/featured-creators')),
    exact: true,
  },
  {
    path: '/admin/reports',
    component: lazy(() => import(/* webpackChunkName: "ReportList" */ './pages/report-list')),
    exact: true,
  },
];

const App: React.FC = () => (
  <Suspense fallback={<>loading</>}>
    <Authenticator>
      <AdminLayout>
        <StreamProvider>
          <Switch>
            {routes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                component={route.component}
                // component={React.lazy(() => import(`${route.component}`))}
              />
            ))}
          </Switch>
        </StreamProvider>
      </AdminLayout>
    </Authenticator>
  </Suspense>
);

export default App;
